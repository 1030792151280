import axios from '../axios';

const requestNewPassword = async (email?: string) => {
  try {
    const { data } = await axios().post(`/password`, {
      email: email,
    });
    return data;
  } catch (error) {
    return error;
  }
};

const resetNewPassword = async (key: string, email: string, password: string) => {
  try {
    const { data } = await axios().put(`/password`, {
      key: key,
      email: email,
      password: password,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export { requestNewPassword, resetNewPassword };
