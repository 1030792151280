import { createReducer } from '@reduxjs/toolkit';
import { deleteUserData, saveUserData } from './actions';

type UserAuthStateType = {
  user?: UserType;
};

const initialState: UserAuthStateType = {
  user: undefined,
};

export const userReducer = createReducer(initialState, builder => {
  builder
    .addCase(saveUserData, (state, action) => {
      state.user = action.payload;
    })
    .addCase(deleteUserData, state => {
      state.user = undefined;
    });
});
