import React from 'react';
import { useTranslation } from 'react-i18next';

import Goback from 'components/ui/go-back';
import TestingProductImage from 'assets/image/placeholder.jpg';
import ListItem from 'components/ui/list-item';
import styles from './style.module.scss';

const ShoppingListProductDetail = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Goback title={`${t('product-back')}`} />
      <img src={TestingProductImage} className={styles.image} />
      <p className={styles.title}>Product Name (Packing Unit)</p>
      <div className={styles.priceWrapper}>
        <p>
          {t('product-price')}: $456 <span className={styles.save}>20% save</span>
        </p>
        <p>{t('QTY')}: 2</p>
      </div>
      <p className={styles.title}>{t('shopping-product-with-other-retailers')}</p>
      <div className={styles.listWrapper}>
        <ListItem type="SHOP" isSimple />
        <ListItem type="SHOP" isSimple />
      </div>
    </div>
  );
};

export default ShoppingListProductDetail;
