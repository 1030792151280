import { Bars } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import Goback from 'components/ui/go-back';
import SolutionCard from 'components/ui/solution-card';
import styles from './style.module.scss';
import { shoppingListSelector } from 'store/shopping/hooks';
import { useAppSelector } from 'store';

const ShoppingListSolutions = () => {
  const { t } = useTranslation();

  const shopping = useAppSelector(shoppingListSelector);

  return (
    <div className={styles.wrapper}>
      <Goback title={`${t('shopping-the-best-solutions')}`} />
      {shopping?.solutionList ? (
        shopping?.solutionList.length > 0 ? (
          <>
            <div className={styles.solutionWrapper}>
              <p className={styles.info}>{`${t('click-each-solution-message')}`}.</p>
              {[...shopping?.solutionList]
                .sort((a, b) => (b?.save_percent ?? 0) - (a?.save_percent ?? 0))
                .map((solution: SolutionType) => (
                  <div key={solution?.title}>
                    <SolutionCard
                      id={solution?.id}
                      retailers={solution?.retailers ?? []}
                      savings={solution?.savings ?? 0}
                      save_percent={solution?.save_percent ?? 0}
                      total={solution?.total ?? 0}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className={styles.noSolutionMessage}>
            <p>{`${t('no-available-solutions')}`}</p>
          </div>
        )
      ) : (
        <div className={styles.loading}>
          <Bars color="#171100" width={30} height={30} />
        </div>
      )}
    </div>
  );
};

export default ShoppingListSolutions;
