const rad = function (x: number) {
  return (x * Math.PI) / 180;
};

export const getDistance = function (lat1: number, lng1: number, lat2: number, lng2: number) {
  const R = 6378137;
  const dLat = rad(lat2 - lat1);
  const dLong = rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};
