import React, { useEffect, useState } from 'react';
import {
  HeartIcon,
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  ShoppingCartIcon,
  MinusIcon,
} from '@heroicons/react/24/solid';
import { HeartIcon as BlankHeartIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from 'components/basic/button';
import CreateShoppingListModal from 'components/ui/create-shoppinglist-modal';
import CustomDisclosure from 'components/basic/disclosure';
import Goback from 'components/ui/go-back';
import LoadingScreen from 'components/ui/loading-screen';
import TestProductImage from 'assets/image/placeholder.jpg';
import styles from './style.module.scss';
import { createFavorite, deleteFavorite, getProductById } from 'utils/api/product';

const ProductDetailMore = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [isFavour, setIsFavour] = useState(false);
  const [product, setProduct] = useState<ProductType>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProductDetail = async () => {
      if (id) {
        setIsLoading(true);
        const res = await getProductById(id);
        setIsFavour(res?.data?.isFavorite);
        setProduct(res?.data);
        setIsLoading(false);
      }
    };
    getProductDetail();
  }, [id]);

  const setAsFavorite = async () => {
    await createFavorite(parseInt(id ?? ''));
    setIsFavour(true);
  };

  const removeFavorite = async () => {
    await deleteFavorite(parseInt(id ?? ''));
    setIsFavour(false);
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Goback title={`${t('product-back')}`} />
        <div className={styles.buttonGroup}>
          <button
            onClick={() => {
              if (isFavour) {
                removeFavorite();
              } else {
                setAsFavorite();
              }
            }}
          >
            {isFavour ? <HeartIcon width={30} height={30} color="red" /> : <BlankHeartIcon width={30} height={30} />}
          </button>
          <button onClick={() => setShowModal(true)} className={styles.shoppingCart}>
            <ShoppingCartIcon width={30} height={30} />
            <PlusSmallIcon width={12} height={12} className={styles.icon} strokeWidth={5} />
          </button>
        </div>
      </div>
      {product && (
        <>
          <div className={styles.thumbnailWrapper}>
            <img
              src={product?.photo ? `${process.env.REACT_APP_API_BASEURL}${product?.photo}` : TestProductImage}
              className={styles.img}
            />
          </div>
          <div className={styles.info}>
            <p className={styles.name}>{product?.name}</p>
            <p className={styles.price}>{`${t('product-price')}: ${product?.price} Ft`}</p>
            <p className={styles.package}>{`${t('product-package')}: ${product?.package} ${product?.unit}`}</p>
          </div>
          <div className={styles.storeInfo}>
            <div>
              <p className={styles.retailer_name}>{product?.retailer_name}</p>
              <p>{`${t('unit-product-price')}: ${product?.unit_price} Ft / ${product?.unit}`}</p>
            </div>
            <div>
              <div className={styles.trending}>
                {(product?.trending ?? 0) > 2 && (
                  <ArrowTrendingUpIcon width={24} height={24} className={styles.chartGood} />
                )}
                {(product?.trending ?? 0) < -2 && (
                  <ArrowTrendingDownIcon width={24} height={24} className={styles.chartDanger} />
                )}
                {(product?.trending ?? 0) >= -2 && (product?.trending ?? 0) <= 2 && (
                  <MinusIcon width={24} height={24} className={styles.chargNormal} />
                )}
                <p>{product?.trending ?? 0}%</p>
              </div>
            </div>
          </div>
          {/* <div className={styles.description}>
            <span dangerouslySetInnerHTML={{ __html: `${product?.description}` }}></span>
          </div> */}
          <div className={styles.otherOffer}>
            {(product?.other_offers ?? []).length > 0 && (
              <p className={styles.title}>{t('product-other-offers-in-this-area')}</p>
            )}
            {product?.other_offers?.map((item: RetailerDetailType, index) => (
              <CustomDisclosure
                key={index}
                buttonElement={
                  <div className={styles.shopInfoWrapper}>
                    <span className={styles.disclosureButton}>{item?.retailer_name}</span>
                    <ArrowTrendingUpIcon width={24} height={24} className={styles.chartGood} />
                    <span>{item?.price} Ft</span>
                  </div>
                }
              >
                <p className={styles.address}>{item?.address}</p>
              </CustomDisclosure>
            ))}
          </div>
          <div className={styles.addToCart}>
            <div className={styles.buttonWrapper}>
              <Button label={`${t('product-add-to-shopping-list')}`} onClick={() => setShowModal(true)} />
            </div>
          </div>
          <CreateShoppingListModal setShowModal={setShowModal} showModal={showModal} product={product} />
        </>
      )}
    </div>
  );
};

export default ProductDetailMore;
