import classNames from 'classnames';
import React, { ReactNode, useState, useEffect } from 'react';

import styles from './style.module.scss';

type CheckboxProps = {
  name: string;
  value?: boolean;
  children?: ReactNode;
  onChange?: (name: string, value: boolean) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({ children, onChange, name, value }) => {
  const [checked, setChecked] = useState(value);
  useEffect(() => {
    setChecked(value);
  }, [value]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.checkWrapper}>
        <label className={styles.checkboxLabel}>
          <input
            type="checkbox"
            name={name}
            className={classNames(styles.checkbox)}
            checked={checked}
            onChange={e => {
              setChecked(e?.target?.checked);
              if (onChange) {
                onChange(name, e.target?.checked);
              }
            }}
          />
        </label>
      </div>
      <div className={styles.label}>{children}</div>
    </div>
  );
};

export default Checkbox;
