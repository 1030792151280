import React from 'react';
import { Bars } from 'react-loader-spinner';
import classNames from 'classnames';

import styles from './styles.module.scss';

type ButtonProps = {
  type?: 'button' | 'submit';
  label: string;
  additionalClass?: string;
  isLoading?: boolean;
  disable?: boolean;
  onClick?: VoidFunction;
};

const Button: React.FC<ButtonProps> = ({
  label = '',
  additionalClass = '',
  isLoading = false,
  type = 'button',
  disable,
  onClick,
}) => {
  return (
    <button type={type} className={classNames(styles.wrapper, additionalClass)} onClick={onClick} disabled={disable}>
      {isLoading ? (
        <Bars
          height="25"
          width="25"
          color="#ffffff"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      ) : (
        <span className={styles.label}>{label}</span>
      )}
    </button>
  );
};

export default Button;
