import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  HeartIcon,
  MinusIcon,
  PlusSmallIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ShoppingCartIcon, HeartIcon as SolidHeartIcon } from '@heroicons/react/24/solid';
import { Bars } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/basic/drawer';
import TextInput from 'components/basic/text-input';
import Dropdown, { OptionType } from 'components/basic/dropdown';
import SampleProductImage from 'assets/image/placeholder.jpg';
import useDebounce from 'hooks/debounce';

import CreateShoppingListModal from '../create-shoppinglist-modal';
import { createFavorite, deleteFavorite, searchProducts } from 'utils/api/product';
import { getCategoryData } from 'utils/api/category';
import styles from './style.module.scss';

type SearchPanelProps = {
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchPanel: React.FC<SearchPanelProps> = ({ isShow, setShow }) => {
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState(false);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [searchString, setSearchString] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<OptionType>({
    name: t('shopping-all'),
    slug: t('shopping-all'),
  });
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState<OptionType>({
    name: t('shopping-all'),
    slug: t('shopping-all'),
  });
  const [selectedType, setSelectedType] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductType | undefined>();
  const [showShoppingModal, setShowShoppingModal] = useState(false);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [subCategories, setSubCategories] = useState<CategoryType[]>([]);
  const navigate = useNavigate();

  const debouncedSearchTerm = useDebounce(searchString, 500);

  useEffect(() => {
    const getCategories = async () => {
      const res = await getCategoryData();
      setCategories(res?.data?.categories ?? []);
    };
    getCategories();
  }, []);

  useEffect(() => {
    setSelectedCategory({ name: t('shopping-all'), slug: t('shopping-all') });
    const id = categories.find(item => item?.name === selectedPrimaryCategory?.name)?.id;
    const getCategories = async () => {
      if (id) {
        const res = await getCategoryData(id);
        setSubCategories(res?.data?.categories ?? []);
      }
    };
    getCategories();
  }, [selectedPrimaryCategory]);

  const getProducts = async () => {
    setIsSearching(true);
    const res = await searchProducts({
      query: debouncedSearchTerm,
      discounted: selectedType,
      category: categories?.find(
        item => item?.name === (selectedCategory.name !== 'All' ? selectedCategory.name : selectedPrimaryCategory.name)
      )?.slug,
    });
    setIsSearching(false);
    setProducts(res?.data?.products ?? []);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      getProducts();
    } else {
      setIsSearching(false);
      setProducts([]);
    }
  }, [debouncedSearchTerm, selectedType, selectedPrimaryCategory, selectedCategory]);

  const setAsFavorite = async (id: number) => {
    await createFavorite(id);
  };

  const removeFavorite = async (id: number) => {
    await deleteFavorite(id);
  };

  const resetPrimaryCategory = () => {
    setSelectedPrimaryCategory({ name: t('shopping-all'), slug: t('shopping-all') });
  };

  const resetCategory = () => {
    setSelectedCategory({ name: t('shopping-all'), slug: t('shopping-all') });
  };

  return (
    <div className={styles.wrapper}>
      <Drawer isShow={isShow} setShow={setShow} title={`${t('search-search')}`}>
        <TextInput
          name="search"
          label={`${t('search-search-by-products-name')}`}
          placeholder={`${t('search-search-by-products-name')}`}
          val={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
        <div className={styles.groupSearch}>
          <div className={styles.tabs}>
            <div
              className={classNames(styles.tabItem, { [styles.active]: !selectedType })}
              onClick={() => setSelectedType(false)}
            >
              {t('search-all-products')}
            </div>
            <div
              className={classNames(styles.tabItem, { [styles.active]: selectedType })}
              onClick={() => setSelectedType(true)}
            >
              {t('search-only-discount-products')}
            </div>
          </div>
        </div>
        {debouncedSearchTerm && (
          <>
            <div className={styles.dropdownWrapper}>
              <Dropdown
                val={selectedPrimaryCategory}
                onChange={setSelectedPrimaryCategory}
                list={categories}
                label={`${t('search-select-main-category')}`}
              />
              {selectedPrimaryCategory?.slug !== 'All' && (
                <XMarkIcon width={25} height={25} className={styles.xMarkIcon} onClick={() => resetPrimaryCategory()} />
              )}
            </div>
            {selectedPrimaryCategory?.slug !== 'All' && (
              <div className={classNames(styles.dropdownWrapper, { 'mt-2': true })}>
                <Dropdown
                  val={selectedCategory}
                  onChange={setSelectedCategory}
                  list={subCategories}
                  label={`${t('search-select-sub-category')}`}
                />
                {selectedCategory?.slug !== 'All' && (
                  <XMarkIcon width={25} height={25} className={styles.xMarkIcon} onClick={() => resetCategory()} />
                )}
              </div>
            )}
          </>
        )}
        {isSearching ? (
          <div className={styles.loading}>
            <Bars color="#171100" width={30} height={30} />
          </div>
        ) : (
          <>
            {products?.length > 0 ? (
              <div className={styles.productSearch}>
                <div className={styles.productList}>
                  {products.map((product, index) => (
                    <div
                      key={index}
                      className={styles.item}
                      onClick={() => {
                        setShow(false);
                        navigate(`/product/${product.id}`);
                      }}
                    >
                      <div className={styles.detail}>
                        <img
                          src={
                            product?.photo
                              ? `${process.env.REACT_APP_BACKEND_BASEURL}/${product?.photo}`
                              : SampleProductImage
                          }
                          className={styles.img}
                        />
                        <div className={styles.descritions}>
                          <div>
                            <p className={styles.name}>{product.name}</p>
                            <p className={styles.price}>{product.price} Ft</p>
                            <div className={styles.savingWrapper}>
                              <p className={styles.saving}>{`${t('shopping-savings')}: ${product.saving ?? 0}`} Ft</p>
                              <div className={styles.trending}>
                                <div>
                                  {(product.trending ?? 0) > 2 && (
                                    <ArrowTrendingUpIcon width={18} height={18} className={styles.chartGood} />
                                  )}
                                  {(product.trending ?? 0) < -2 && (
                                    <ArrowTrendingDownIcon width={18} height={18} className={styles.chartDanger} />
                                  )}
                                  {(product.trending ?? 0) >= -2 && (product.trending ?? 0) <= 2 && (
                                    <MinusIcon width={18} height={18} className={styles.chargNormal} />
                                  )}
                                </div>
                                <p>{product.trending ?? 0}%</p>
                              </div>
                            </div>
                          </div>
                          <p className={styles.description}>{product.description}</p>
                        </div>
                      </div>
                      <div className={styles.cart}>
                        <div
                          onClick={async e => {
                            e.stopPropagation();
                            if (product?.isFavorite) {
                              await removeFavorite(product?.id);
                            } else {
                              await setAsFavorite(product?.id);
                            }
                            await getProducts();
                          }}
                        >
                          {product?.isFavorite ? (
                            <SolidHeartIcon width={30} height={30} color="red" />
                          ) : (
                            <HeartIcon width={30} height={30} />
                          )}
                        </div>
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            setSelectedProduct(product);
                            setShowShoppingModal(true);
                          }}
                          className={styles.shoppingCart}
                        >
                          <ShoppingCartIcon width={30} height={30} className={styles.shoppingCartIcon} />
                          <PlusSmallIcon width={12} height={12} className={styles.plusIcon} strokeWidth={5} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className={styles.noProduct}>{t('search-no-product')}</div>
            )}
          </>
        )}
      </Drawer>
      <CreateShoppingListModal
        showModal={showShoppingModal}
        setShowModal={setShowShoppingModal}
        product={selectedProduct}
      />
    </div>
  );
};

export default SearchPanel;
