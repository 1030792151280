import axios from '../axios';

const getBlogData = async (id?: string) => {
  try {
    const resource = id ? `/blogs/${id}` : '/blogs';
    const { data } = await axios(true).get(resource);
    return data;
  } catch (error) {
    return [];
  }
};

export { getBlogData };
