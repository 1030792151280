import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/outline';

import styles from './style.module.scss';

type DrawerProps = {
  title?: string;
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
};

const Drawer: React.FC<DrawerProps> = ({ title = '', isShow, setShow, children }) => {
  return (
    <Transition.Root show={isShow} as={Fragment}>
      <Dialog as="div" auto-reopen="true" className={styles.wrapper} onClose={setShow}>
        <div className={styles.content}>
          <Dialog.Overlay className={styles.overlay} />

          <div className={styles.dialog}>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={styles.contentWrapper}>
                <div className={styles.drawerBody}>
                  <div className={styles.drawerHeader}>
                    <Dialog.Title className={styles.title}>{title}</Dialog.Title>
                    <button className={styles.button} onClick={() => setShow(false)}>
                      <XCircleIcon width={30} height={30} aria-hidden="true" />
                    </button>
                  </div>
                  <div className={styles.body}>
                    {/* Replace with your content */}
                    <div className={styles.bodyContent}>{children}</div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;
