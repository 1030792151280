import AldiMarker from 'assets/image/marker_aldi.svg';
import AuchanMarker from 'assets/image/marker_auchan.svg';
import DmMarker from 'assets/image/marker_dm.svg';
import LidlMarker from 'assets/image/marker_lidl.svg';
import RossmannMarker from 'assets/image/marker_rossmann.svg';
import SparMarker from 'assets/image/marker_spar.svg';
import TescoMarker from 'assets/image/marker_tesco.svg';
import EcoFamilyMarker from 'assets/image/marker_ecofamily.svg';
import PennyMarker from 'assets/image/marker_penny.svg';

export const RETAILERS = [
  {
    name: 'Aldi',
    color: '#ADD8E6',
    icon: AldiMarker,
  },
  {
    name: 'Auchan',
    color: '#8B0000',
    icon: AuchanMarker,
  },
  {
    name: 'DM',
    color: '#808080',
    icon: DmMarker,
  },
  {
    name: 'Lidl',
    color: '#00008B',
    icon: LidlMarker,
  },
  {
    name: 'Rossmann',
    color: '#953553',
    icon: RossmannMarker,
  },
  {
    name: 'Spar',
    color: '#023020',
    icon: SparMarker,
  },
  {
    name: 'Tesco',
    color: '#FFCCCB',
    icon: TescoMarker,
  },
  {
    name: 'EcoFamily',
    color: '#90EE90',
    icon: EcoFamilyMarker,
  },
  {
    name: 'Penny',
    color: 'Yellow',
    icon: PennyMarker,
  },
];
