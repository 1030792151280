import 'react-toastify/dist/ReactToastify.css';
import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import { LatLng } from 'leaflet';
import { MapContainer, TileLayer, Marker, useMapEvents, Circle, Popup } from 'react-leaflet';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/basic/button';
import Checkbox from 'components/basic/checkbox';
import LoadingScreen from 'components/ui/loading-screen';
import Modal from 'components/basic/modal';
import styles from './style.module.scss';
import { RETAILERS } from 'utils/const';
import { getDistance } from 'utils/helper';
import { getRetailerData, getShopData, getZone, saveZone } from 'utils/api/shop';

type LocationMarkerProps = {
  position: LatLng | undefined;
  setPosition: React.Dispatch<React.SetStateAction<LatLng | undefined>>;
};

const LocationMarker: React.FC<LocationMarkerProps> = ({ position, setPosition }) => {
  useMapEvents({
    click(e) {
      setPosition(e?.latlng);
    },
  });

  return position ? <Marker position={position}></Marker> : null;
};

const marker = (name: string) =>
  new L.Icon({
    iconUrl: RETAILERS.find(item => item.name === name)?.icon,
    iconRetinaUrl: RETAILERS.find(item => item.name === name)?.icon,
    iconSize: new L.Point(20, 20),
    className: 'tag_icon_pedestrian',
  });

const SelectShoppingZone = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState<LatLng>();
  const [showModal, setShowModal] = useState(true);
  const [radius, setRadius] = useState<number>(10);
  const [selectedRetailers, setSelectedRetailers] = useState<(RetailerType | undefined)[]>([]);
  const [shops, setShops] = useState<ShopType[]>([]);
  const [filteredShops, setFilteredShop] = useState<ShopType[]>([]);
  const [retailers, setRetailers] = useState<RetailerType[]>([]);
  const [selectedShops, setSelectedShops] = useState<ShopType[]>([]);

  useEffect(() => {
    const getShops = async () => {
      setIsLoading(true);
      const retailersRes = await getRetailerData();
      setRetailers(retailersRes?.data?.retailers ?? []);
      setSelectedRetailers(retailersRes?.data?.retailers ?? []);
      const shopsRes = await getShopData();
      setShops(shopsRes?.data?.shops ?? []);
      setFilteredShop(shopsRes?.data?.shops ?? []);
      const res = await getZone();
      setRadius(res?.data?.diameter);
      setPosition(prev => ({ ...prev, lat: res?.data?.latitude ?? 0, lng: res?.data?.longitude ?? 0 } as LatLng));
      setSelectedRetailers(
        (res?.data?.retailers ?? []).map((item: number) =>
          (retailersRes?.data?.retailers ?? [])?.find((ret: RetailerType) => ret.id === item)
        )
      );
      setIsLoading(false);
    };
    getShops();
  }, []);

  useEffect(() => {
    setFilteredShop(shops.filter(item => selectedRetailers?.find(ret => ret?.id === item.retailer_id)));
    if (position && radius) {
      setSelectedShops(
        shops.filter(
          item =>
            selectedRetailers?.find(ret => ret?.id === item.retailer_id) &&
            getDistance(
              position?.lat ?? 0,
              position?.lng ?? 0,
              parseFloat(item.latitude) ?? 0,
              parseFloat(item.longitude) ?? 0
            ) < radius
        )
      );
    }
  }, [radius, position, selectedRetailers]);

  const onSaveZone = async () => {
    if (position?.lat === 0 && position?.lng === 0) {
      toast.error(`${t('zone-no-mark')}`);
      return;
    }

    if (!radius) {
      toast.error(`${t('zone-no-radius')}`);
      return;
    }

    if (selectedShops?.length === 0) {
      const unSelectedRetailers = retailers?.filter(obj => !selectedRetailers?.some(ret => obj.id === ret?.id));
      const otherShops = shops?.filter(
        item =>
          unSelectedRetailers?.find(ret => ret?.id === item.retailer_id) &&
          getDistance(
            position?.lat ?? 0,
            position?.lng ?? 0,
            parseFloat(item.latitude) ?? 0,
            parseFloat(item.longitude) ?? 0
          ) < radius
      );

      const arr = otherShops.map(item => retailers?.find(retailer => retailer?.id === item?.retailer_id)?.name);
      const shopNames = [...new Set(arr)].filter(Boolean).join(', ');

      if (otherShops?.length > 0) {
        toast.error(`${t('zone-other-retailer-shops-available', { value: shopNames })}`);
      } else {
        toast.error(`${t('zone-no-shops')}`);
      }

      return;
    }

    setIsLoading(true);
    await saveZone({
      latitude: position?.lat ?? 0,
      longitude: position?.lng ?? 0,
      diameter: radius,
      retailers: selectedRetailers?.map(item => item?.id),
    });
    setIsLoading(false);
    navigator('/');
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className={styles.wrapper}>
      <MapContainer center={[47.49, 19.04]} zoom={13} scrollWheelZoom={true} className={styles.map}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {position ? (
          <Circle center={[position?.lat, position?.lng]} pathOptions={{ fillColor: 'blue' }} radius={radius} />
        ) : null}
        <LocationMarker position={position} setPosition={setPosition} />
        {filteredShops?.map(shop => (
          <Marker
            position={[parseFloat(shop.latitude), parseFloat(shop.longitude)]}
            key={shop.id}
            icon={marker(retailers.find(item => item.id === shop.retailer_id)?.name ?? 'Aldi')}
          >
            <Popup>
              <p>{retailers.find(item => item.id === shop.retailer_id)?.name ?? 'Aldi'}</p>
              <p>{shop.address}</p>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <div className={styles.control}>
        <p className={styles.label}>{`Radius (${radius / 1000} KM)`}</p>
        <input
          id="typeinp"
          type="range"
          min="0"
          max="25000"
          value={radius}
          onChange={e => setRadius(parseInt(e.target.value))}
          step="1"
          className={styles.input}
        />
        <div className={styles.buttonWrapper}>
          <button className={styles.button} onClick={() => setShowModal(true)}>
            {t('filter-retailers')}
          </button>
          <button className={styles.button} onClick={onSaveZone}>
            {t('save-zone')}
          </button>
          <ToastContainer
            position="top-center"
            autoClose={10000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            theme="light"
            style={{
              padding: '20px',
            }}
          />
        </div>
        <p className={styles.description}>{`${t('save-zone-description')}`}</p>
      </div>
      <div className={styles.table}>
        <ul>
          <li>
            {t('shops-in-zone')}: {selectedShops?.length}
          </li>
          {retailers?.map(retailer => (
            <li key={retailer?.name} className={styles.item}>
              <div
                className={styles.circle}
                style={{ backgroundColor: RETAILERS.find(item => item.name === retailer?.name)?.color }}
              ></div>
              {retailer?.name}: {selectedShops?.filter(item => item.retailer_id === retailer.id)?.length ?? 0}
            </li>
          ))}
        </ul>
      </div>
      <Modal isOpen={showModal} setIsOpen={setShowModal} close={false} showModal={true}>
        <div className={styles.modalWrapper}>
          {retailers.map(retailer => (
            <Checkbox
              name={retailer.name}
              value={!!selectedRetailers?.find(item => retailer?.id === item?.id)}
              key={retailer?.id}
              onChange={(name, value) => {
                const checkedRetailer = retailers?.find(item => item.name === name);
                if (value && checkedRetailer) {
                  setSelectedRetailers(prev => [...prev, checkedRetailer]);
                } else {
                  setSelectedRetailers(prev => [...prev.filter(item => item?.id !== checkedRetailer?.id)]);
                }
              }}
            >
              {retailer.name}
            </Checkbox>
          ))}
        </div>
        <div className="mt-6">
          <Button
            type="button"
            label={t('shopping-next')}
            onClick={() => setShowModal(false)}
            disable={!selectedRetailers?.length}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SelectShoppingZone;
