import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import axios from 'utils/axios';
import classNames from 'classnames';
import { FieldValues, useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/basic/button';
import Logo from 'assets/image/logo.png';
import TextInput from 'components/basic/text-input';
import styles from './styles.module.scss';
import { saveUserData } from 'store/user/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { userSelector } from 'store/user/hooks';

const SignUp = () => {
  const lang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  const navigator = useNavigate();

  const auth = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  const [responseMsg, setResponseMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(lang === 'hu');

  const schema = yup.object().shape({
    firstname: yup.string().required(`${t('auth-enter-your-name')}`),
    email: yup.string().email('email-no-match').required('enter-your-email'),
    password: yup.string().required(`${t('auth-enter-your-password')}`),
    confirmPassword: yup
      .string()
      .required('enter-confirm-password')
      .oneOf([yup.ref('password'), ''], 'password-no-match'),
  });

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (enabled) {
      i18n.changeLanguage('hu');
      localStorage.setItem('lang', 'hu');
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
    }
  }, [enabled]);

  const onSubmit = async (data: FieldValues) => {
    try {
      setIsLoading(true);
      const res = await axios(false).post('/register', {
        email: data.email,
        password: data.password,
        firstname: data.firstname,
      });
      setResponseMsg(res?.data?.data?.message);
      if (res?.data?.data?.success) {
        localStorage.setItem('email', res?.data?.data?.email ?? '');
        localStorage.setItem('token', res?.data?.data?.token ?? '');
        dispatch(saveUserData(res?.data?.data));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return auth?.token ? (
    auth?.has_zone ? (
      <Navigate to="/" replace />
    ) : (
      <div className={styles.zoneWrapper}>
        <div className={styles.toggleWrapper}>
          <span className="text-12 text-secondary font-bold">En/Hu</span>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(styles.toggle, { [styles.active]: enabled })}
          >
            <span className={classNames(styles.toggleCircle, { [styles.active]: enabled })} />
          </Switch>
        </div>
        <div className={styles.logo}>
          <img src={Logo} className={styles.logoImage} />
          <p className={styles.welcome}>{`${t('welcome-to')}`}</p>
          <p className={styles.appName}>{`${t('bakery-app')}`}</p>
        </div>
        <div className={styles.message}>
          <p className={styles.title}>{`${t('select-shopping-zone-title')}`}</p>
          <p className={styles.description}>{`${t('select-shopping-zone-content')}`}</p>
          <Button type="button" label={`${t('shopping-ok')}`} onClick={() => navigator('/zone')} />
        </div>
      </div>
    )
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.toggleWrapper}>
        <span className="text-12 text-secondary font-bold">En/Hu</span>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(styles.toggle, { [styles.active]: enabled })}
        >
          <span className={classNames(styles.toggleCircle, { [styles.active]: enabled })} />
        </Switch>
      </div>
      <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.logo}>
          <img src={Logo} className={styles.logoImage} />
          <p className={styles.welcome}>{`${t('welcome-to')}`}</p>
          <p className={styles.appName}>{`${t('bakery-app')}`}</p>
        </div>
        <div className={styles.body}>
          <p className={styles.title}>{`${t('auth-create-account')}`}</p>
          {[
            { name: 'firstname', label: `${t('auth-first-name')}`, type: 'text' },
            { name: 'email', label: `${t('auth-email')}`, type: 'text' },
            { name: 'password', label: `${t('auth-password')}`, type: 'password' },
            { name: 'confirmPassword', label: `${t('auth-confirm-password')}`, type: 'password' },
          ].map(item => (
            <div key={item.name}>
              <TextInput
                type={item.type}
                name={item.name}
                label={item.label}
                placeholder={item.label}
                register={formRegister(item.name)}
              />
              {errors &&
                errors[item.name] &&
                (() => {
                  switch (item.name) {
                    case 'firstname':
                      return <span className={styles.error}>{`${t('auth-enter-your-name')}`}</span>;
                    case 'email':
                      return errors[item.name]?.message === 'enter-your-email' ? (
                        <span className={styles.error}>{`${t('auth-enter-your-email')}`}</span>
                      ) : (
                        <span className={styles.error}>{`${t('auth-email-no-match')}`}</span>
                      );
                    case 'password':
                      return <span className={styles.error}>{`${t('auth-enter-your-password')}`}</span>;
                    case 'confirmPassword':
                      return errors[item.name]?.message === 'enter-confirm-password' ? (
                        <span className={styles.error}>{`${t('auth-confirm-your-password')}`}</span>
                      ) : (
                        <span className={styles.error}>{`${t('auth-password-no-match')}`}</span>
                      );
                    default:
                      return null;
                  }
                })()}
            </div>
          ))}
        </div>
        <div className={styles.buttonGroup}>
          <Button type="submit" label={`${t('auth-take-it')}`} isLoading={isLoading} />
          {responseMsg &&
            (() => {
              switch (responseMsg) {
                case 'USED':
                  return <span className={styles.error}>{`${t('auth-email-used')}`}</span>;
                case 'SHORTPASS':
                  return <span className={styles.error}>{`${t('auth-short-password')}`}</span>;
                case 'INVALID':
                  return <span className={styles.error}>{`${t('auth-invalid-email')}`}</span>;
                default:
                  return <span className={styles.error}>{`${t('auth-signup-failed')}`}</span>;
              }
            })()}
          <p className={styles.message}>
            {`${t('auth-already-have-account')}`}{' '}
            <Link to={'/sign-in'}>
              <span className={styles.link}>{`${t('auth-login')}`}</span>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
