import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'components/basic/button';
import styles from './style.module.scss';

const CoommingSoon = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <p>{t('comming-soon-message')}</p>
        <div className={styles.button}>
          <Link to="/">
            <Button label={`${t('nav-home')}`} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CoommingSoon;
