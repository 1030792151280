import React, { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';

import Goback from 'components/ui/go-back';
import PlaceHolderImage from 'assets/image/placeholder.jpg';
import styles from './style.module.scss';
import { getBlogData } from 'utils/api/blog';

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState<BlogType | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBlogDetail = async () => {
      if (id) {
        setLoading(true);
        const res = await getBlogData(id);
        setBlog(res?.data);
        setLoading(false);
      } else {
        navigate('/');
      }
    };
    getBlogDetail();
  }, []);

  return !loading ? (
    <div className={styles.wrapper}>
      {blog && (
        <>
          <Goback title={blog?.title} />
          <img
            src={blog?.photo ? `${process.env.REACT_APP_BACKEND_BASEURL}${blog?.photo}` : PlaceHolderImage}
            className={styles.image}
          />
          <p className={styles.title}>{blog?.title}</p>
          <p className={styles.content}>{blog?.intro}</p>
          <p className={styles.content}>{blog?.body ?? ''}</p>
          <p className={styles.author}>Created At: {new Date(blog?.created).toDateString()}</p>
        </>
      )}
    </div>
  ) : (
    <div className={styles.loading}>
      <Bars color="#171100" width={30} height={30} />
    </div>
  );
};

export default BlogDetail;
