import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import Button from 'components/basic/button';
import Logo from 'assets/image/logo.png';
import TextInput from 'components/basic/text-input';
import styles from './styles.module.scss';
import { resetNewPassword } from '../../utils/api/auth';
import { useAppDispatch, useAppSelector } from 'store';
import { saveUserData } from 'store/user/actions';
import { userSelector } from 'store/user/hooks';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

const schema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
});

const ResetPassword = () => {
  const auth = useAppSelector(userSelector);
  const dispatch = useAppDispatch();
  const [responseMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigator = useNavigate();
  const lang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  const [enabled, setEnabled] = useState(lang === 'hu');

  useEffect(() => {
    if (enabled) {
      i18n.changeLanguage('hu');
      localStorage.setItem('lang', 'hu');
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
    }
  }, [enabled]);

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const key = urlParams.get('key');
      const email = urlParams.get('email');
      const response = await resetNewPassword(key ?? '', email ?? '', data.password);
      if (response.data?.success) {
        try {
          const res = await axios.post('/login', { email: email, password: data.password });
          if (res?.data?.data?.success) {
            setIsLoading(false);
            localStorage.setItem('email', res?.data?.data?.email ?? '');
            localStorage.setItem('token', res?.data?.data?.token ?? '');
            dispatch(saveUserData(res?.data?.data));
            navigator('/');
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return auth?.token ? (
    <Navigate to="/" replace />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.toggleWrapper}>
        <span className="font-bold text-12 text-secondary">En/Hu</span>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(styles.toggle, { [styles.active]: enabled })}
        >
          <span className={classNames(styles.toggleCircle, { [styles.active]: enabled })} />
        </Switch>
      </div>
      <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.logo}>
          <img src={Logo} className={styles.logoImage} />
          <p className={styles.appName}>{t('app-name')}</p>
        </div>
        <div className={styles.body}>
          <p className={styles.title}>{t('auth-reset-password')}</p>
          {[
            { name: 'password', label: `${t('auth-password')}`, type: 'password' },
            { name: 'confirmPassword', label: `${t('auth-confirm-password')}`, type: 'password' },
          ].map(item => (
            <div key={item.name}>
              <TextInput
                type={item.type}
                name={item.name}
                label={item.label}
                placeholder={item.label}
                register={formRegister(item.name)}
              />
              {errors &&
                errors[item.name] &&
                (item.name === 'password' ? (
                  <span className={styles.error}>{`${t('auth-enter-your-password')}`}</span>
                ) : (
                  <span className={styles.error}>{`${t('auth-confirm-your-password')}`}</span>
                ))}
            </div>
          ))}
        </div>
        <div className={styles.buttonGroup}>
          <Button type="submit" label={`${t('auth-take-it')}`} isLoading={isLoading} />
          {responseMsg && <span className={styles.error}>{responseMsg}</span>}
          <p className={styles.message}>
            {`${t('auth-already-have-account')}`}{' '}
            <Link to={'/sign-in'}>
              <span className={styles.link}>{`${t('auth-login')}`}</span>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
