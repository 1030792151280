import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/basic/button';
import Logo from 'assets/image/logo.png';
import TextInput from 'components/basic/text-input';
import styles from './styles.module.scss';
import { requestNewPassword } from '../../utils/api/auth';
import { useAppSelector } from 'store';
import { userSelector } from 'store/user/hooks';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

const schema = yup.object().shape({
  email: yup.string().email('Email no match its format').required('Email is required'),
});

const ForgotPassword = () => {
  const auth = useAppSelector(userSelector);
  const [responseMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false);

  const lang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  const [enabled, setEnabled] = useState(lang === 'hu');

  useEffect(() => {
    if (enabled) {
      i18n.changeLanguage('hu');
      localStorage.setItem('lang', 'hu');
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
    }
  }, [enabled]);

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    try {
      await requestNewPassword(data.email);
      setIsLoading(false);
      setIsShowMessage(true);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return auth?.token ? (
    <Navigate to="/" replace />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.toggleWrapper}>
        <span className="font-bold text-12 text-secondary">En/Hu</span>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(styles.toggle, { [styles.active]: enabled })}
        >
          <span className={classNames(styles.toggleCircle, { [styles.active]: enabled })} />
        </Switch>
      </div>
      <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.logo}>
          <img src={Logo} className={styles.logoImage} />
          <p className={styles.appName}>{t('app-name')}</p>
        </div>
        <div className={styles.body}>
          <p className={styles.title}>{t('auth-forgot-password')}</p>
          {[{ name: 'email', label: `${t('auth-email')}`, type: 'text' }].map(item => (
            <div key={item.name}>
              <TextInput
                type={item.type}
                name={item.name}
                label={item.label}
                placeholder={item.label}
                register={formRegister(item.name)}
              />
              {errors &&
                errors[item.name] &&
                (errors[item.name]?.type === 'required' ? (
                  <span className={styles.error}>{`${t('auth-enter-your-email')}`}</span>
                ) : (
                  <span className={styles.error}>{`${t('auth-email-no-match')}`}</span>
                ))}
              {isShowMessage && <p className={styles.message}>{`${t('auth-email-sent')}`}</p>}
            </div>
          ))}
        </div>
        <div className={styles.buttonGroup}>
          <Button type="submit" label={`${t('auth-send-email')}`} isLoading={isLoading} />
          {responseMsg && <span className={styles.error}>{responseMsg}</span>}
          <p className={styles.message}>
            {`${t('auth-eliminate')}`}{' '}
            <Link to={'/sign-in'}>
              <span className={styles.link}>{`${t('auth-login')}`}</span>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
