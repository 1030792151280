import React, { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  HeartIcon,
  MinusIcon,
  PlusSmallIcon,
} from '@heroicons/react/24/outline';
import { ShoppingCartIcon, HeartIcon as SolidHeartIcon } from '@heroicons/react/24/solid';

import PlaceholderImage from 'assets/image/placeholder.jpg';
import CreateShoppingListModal from '../create-shoppinglist-modal';
import { createFavorite, deleteFavorite } from 'utils/api/product';
import styles from './style.module.scss';

const ProductCard: React.FC<ProductType> = ({ id, name, photo, price, address, trending, isFavorite }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFavour, setIsFavour] = useState(!!isFavorite);
  const [showModal, setShowModal] = useState(false);

  const setAsFavorite = async () => {
    setIsLoading(true);
    await createFavorite(id);
    setIsFavour(true);
    setIsLoading(false);
  };

  const removeFavorite = async () => {
    setIsLoading(true);
    await deleteFavorite(id);
    setIsFavour(false);
    setIsLoading(false);
  };

  return (
    <div className={styles.wrapper} onClick={() => navigate(`/product/${id}`)}>
      <div className={styles.imageWrapper}>
        <img
          src={photo ? `${process.env.REACT_APP_BACKEND_BASEURL}/${photo}` : PlaceholderImage}
          className={styles.image}
        />
      </div>
      <div className={styles.infoPanel}>
        <div className={styles.nameWrapper}>
          <p className={styles.name}>{name}</p>
        </div>
        <p className={styles.address}>{address}</p>
        <div className={styles.priceWrapper}>
          <div className={styles.trending}>
            {(trending ?? 0) > 2 && <ArrowTrendingUpIcon width={18} height={18} className={styles.chartGood} />}
            {(trending ?? 0) < -2 && <ArrowTrendingDownIcon width={18} height={18} className={styles.chartDanger} />}
            {(trending ?? 0) >= -2 && (trending ?? 0) <= 2 && (
              <MinusIcon width={18} height={18} className={styles.chargNormal} />
            )}
            <p>{trending ?? 0}%</p>
          </div>
          <p className={styles.price}>{`${price ?? 0} Ft`}</p>
        </div>
        <div className={styles.actionWrapper}>
          {isFavour ? (
            <button
              onClick={e => {
                e.stopPropagation();
                removeFavorite();
              }}
            >
              {isLoading ? (
                <Bars color="#171100" width={24} height={24} />
              ) : (
                <SolidHeartIcon color="red" width={24} height={24} />
              )}
            </button>
          ) : (
            <button
              onClick={e => {
                e.stopPropagation();
                setAsFavorite();
              }}
            >
              <HeartIcon width={24} height={24} />
            </button>
          )}
          <button
            onClick={e => {
              e.stopPropagation();
              setShowModal(true);
            }}
            className={styles.shoppingCart}
          >
            <ShoppingCartIcon width={24} height={24} className={styles.shoppingCartIcon} />
            <PlusSmallIcon width={12} height={12} className={styles.plusIcon} strokeWidth={5} />
          </button>
        </div>
      </div>
      <CreateShoppingListModal setShowModal={setShowModal} showModal={showModal} product={{ id, name, price }} />
    </div>
  );
};

export default memo(ProductCard);
