import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';

import styles from './style.module.scss';

type PlusMinusInputProps = {
  value: number;
  onPlus: VoidFunction;
  onMinus: VoidFunction;
};

const PlusMinusInput: React.FC<PlusMinusInputProps> = ({ value = 0, onPlus, onMinus }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{t('QTY')}</p>
      <div className={styles.control}>
        <button onClick={onMinus}>
          <MinusCircleIcon width={24} height={24} color="red" className={styles.icon} />
        </button>
        <p className={styles.number}>{value}</p>
        <button onClick={onPlus}>
          <PlusCircleIcon width={24} height={24} color="red" className={styles.icon} />
        </button>
      </div>
    </div>
  );
};

export default PlusMinusInput;
