import React from 'react';
import { Link } from 'react-router-dom';

import PlaceHolderImage from 'assets/image/placeholder.jpg';
import styles from './style.module.scss';

const BlogItem: React.FC<BlogType> = ({ id, title, intro, photo }) => {
  return (
    <div className={styles.wrapper}>
      <Link to={`/news/${id}`}>
        <img
          src={photo ? `${process.env.REACT_APP_BACKEND_BASEURL}${photo}` : PlaceHolderImage}
          className={styles.image}
        />
        <p className={styles.title}>{title}</p>
        <p className={styles.intro}>{intro}</p>
      </Link>
    </div>
  );
};

export default BlogItem;
