import React, { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';

import styles from './style.module.scss';
import ProductCard from 'components/ui/product-card';
import { getProducts } from 'utils/api/product';

const Favourite = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<ProductType[]>([]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const productsData = await getProducts();
      setProducts((productsData?.data?.products ?? []).filter((item: ProductType) => item?.isFavorite));
      setIsLoading(false);
    };
    getData();
  }, []);

  return isLoading ? (
    <div className={styles.loading}>
      <Bars color="#171100" width={30} height={30} />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Link to={'/'}>
          <ChevronLeftIcon width={24} height={24} />
        </Link>
        <p className={styles.title}>{t('favour-favorite-products')}</p>
      </div>
      {products.length > 0 ? (
        <div className={styles.productList}>
          {products.map(product => (
            <ProductCard {...product} key={product?.id} />
          ))}
        </div>
      ) : (
        <p className={styles.noProduct}>{t('search-no-favorite-product')}</p>
      )}
    </div>
  );
};

export default Favourite;
