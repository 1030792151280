import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'utils/axios';

export const getShoppingLists = createAsyncThunk('shopping/get', async () => {
  try {
    const { data } = await axios(true).get('/lists');
    return data;
  } catch (error) {
    return null;
  }
});

export const createShoppingList = createAsyncThunk('shopping/create', async (name: string) => {
  try {
    await axios(true).post('/lists', { name, products: [] });
    const { data } = await axios(true).get('/lists');
    return data;
  } catch (error) {
    return null;
  }
});

export const updateShoppingList = createAsyncThunk('shopping/update', async (list: ShoppingListType) => {
  try {
    await axios(true).put(`/lists/${list?.id}`, { name: list.name, products: list.products, created: list.created });
    const { data } = await axios(true).get('/lists');
    return data;
  } catch (error) {
    return null;
  }
});

export const deleteShoppingList = createAsyncThunk('shopping/delete', async (id: number) => {
  try {
    await axios(true).delete(`/lists/${id}`);
    const { data } = await axios(true).get('/lists');
    return data;
  } catch (error) {
    return null;
  }
});

export const updateQuantityOfProducts = createAsyncThunk(
  'shopping/update-quantity',
  async (req: { list_id: number; product_id: number; quantity: number }) => {
    await axios(true).put(`/lists/${req.list_id}/products`, {
      product_id: req.product_id,
      quantity: req.quantity,
    });
    const { data } = await axios(true).get('/lists');
    return data;
  }
);

export const getShoppingListDetail = createAsyncThunk('shopping/list-detail', async (id: number) => {
  const { data } = await axios(true).get(`/lists/${id}`);
  return data;
});

export const saveSelectedSolution = createAsyncThunk('shopping/save-solution', async (req: { id: number }) => {
  const { data } = await axios(true).post(`/lists/saved`, {
    id: req.id,
  });
  return data;
});

export const getSavedSolutionList = createAsyncThunk('shopping/get-solution', async () => {
  const { data } = await axios(true).get(`/lists/saved`);
  return data;
});

export const deleteSavedSolution = createAsyncThunk('shopping/delete-solution', async (id: number) => {
  try {
    await axios(true).delete(`/lists/saved/${id}`);
    const { data } = await axios(true).get('/lists/saved');
    return data;
  } catch (error) {
    return null;
  }
});

export const saveSelectedList = createAction<ShoppingListType>('shopping/selectedList');
export const saveSolutionList = createAction<[]>('shopping/solutionList');
