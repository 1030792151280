import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';

import styles from './style.module.scss';

type CustomDisclosureProps = {
  buttonElement?: ReactNode;
  actionElement?: ReactNode;
  children: ReactNode;
};

const CustomDisclosure: React.FC<CustomDisclosureProps> = ({ children, buttonElement, actionElement }) => {
  return (
    <div className={styles.wrapper}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className={styles.button}>
              <div className={styles.caption}>{buttonElement}</div>
              <div className={styles.iconWrapper}>
                {actionElement ? (
                  actionElement
                ) : (
                  <ChevronUpIcon className={classNames(styles.icon, { [styles.active]: open })} />
                )}
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className={styles.pannel}>{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default CustomDisclosure;
