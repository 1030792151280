import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';

import styles from './style.module.scss';

const ShoppingListItem: React.FC<ShoppingListType> = ({ name, product_count, created }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.shoppinListItem}>
        <div className={styles.buttonWrapper}>
          <div className={styles.shoppingCart}>
            <ShoppingCartIcon width={30} height={30} className={styles.shoppingCartIcon} />
            <PlusSmallIcon width={12} height={12} className={styles.plusIcon} strokeWidth={5} />
          </div>
          <div className={styles.info}>
            <p className={styles.name}>{`${name} (${product_count} ${t('search-products')})`}</p>
            <p className={styles.date}>{`${t('shopping-created')}: ${created}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingListItem;
