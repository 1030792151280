import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FieldValues, useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/basic/button';
import Logo from 'assets/image/logo.png';
import TextInput from 'components/basic/text-input';
import axios from 'utils/axios';
import styles from './styles.module.scss';
import { saveUserData } from 'store/user/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { userSelector } from 'store/user/hooks';

const SignIn = () => {
  const lang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  const navigator = useNavigate();

  const auth = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  const [responseMsg, setResponseMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(lang === 'hu');

  const schema = yup.object().shape({
    email: yup.string().required(`${t('auth-enter-your-email')}`),
    password: yup.string().required(`${t('auth-enter-your-password')}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (enabled) {
      i18n.changeLanguage('hu');
      localStorage.setItem('lang', 'hu');
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
    }
  }, [enabled]);

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    try {
      const res = await axios(false).post('/login', { email: data.email, password: data.password });
      setResponseMsg(res?.data?.data?.message);
      if (res?.data?.data?.success) {
        localStorage.setItem('email', res?.data?.data?.email ?? '');
        localStorage.setItem('token', res?.data?.data?.token ?? '');
        dispatch(saveUserData(res?.data?.data));
      }
    } catch (error) {
      console.error('error: ', error);
    }
    setIsLoading(false);
  };

  return auth?.token ? (
    auth?.has_zone ? (
      <Navigate to="/" replace />
    ) : (
      <div className={styles.zoneWrapper}>
        <div className={styles.toggleWrapper}>
          <span className="text-12 text-secondary font-bold">En/Hu</span>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(styles.toggle, { [styles.active]: enabled })}
          >
            <span className={classNames(styles.toggleCircle, { [styles.active]: enabled })} />
          </Switch>
        </div>
        <div className={styles.logo}>
          <img src={Logo} className={styles.logoImage} />
          <p className={styles.welcome}>{`${t('welcome-to')}`}</p>
          <p className={styles.appName}>{`${t('bakery-app')}`}</p>
        </div>
        <div className={styles.message}>
          <p className={styles.title}>{`${t('select-shopping-zone-title')}`}</p>
          <p className={styles.description}>{`${t('select-shopping-zone-content')}`}</p>
          <Button type="button" label={`${t('shopping-ok')}`} onClick={() => navigator('/zone')} />
        </div>
      </div>
    )
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.toggleWrapper}>
        <span className="text-12 text-secondary font-bold">En/Hu</span>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(styles.toggle, { [styles.active]: enabled })}
        >
          <span className={classNames(styles.toggleCircle, { [styles.active]: enabled })} />
        </Switch>
      </div>
      <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.logo}>
          <img src={Logo} className={styles.logoImage} />
          <p className={styles.welcome}>{`${t('welcome-to')}`}</p>
          <p className={styles.appName}>{`${t('bakery-app')}`}</p>
        </div>
        <div className={styles.inputGroup}>
          <div>
            <TextInput
              type="text"
              name="email"
              placeholder={`${t('auth-email')}`}
              label={`${t('auth-email')}`}
              register={register('email')}
            />
            {errors?.email && <span className={styles.error}>{`${t('auth-enter-your-email')}`}</span>}
          </div>
          <div>
            <TextInput
              type="password"
              name="password"
              placeholder={`${t('auth-password')}`}
              label={`${t('auth-password')}`}
              register={register('password')}
            />
            {errors?.password && <p className={styles.error}>{`${t('auth-enter-your-password')}`}</p>}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button type="submit" label={`${t('auth-entry')}`} isLoading={isLoading} />
          {responseMsg && <span className={styles.error}>{`${t('auth-invalid-username-or-password')}`}</span>}
          <p className={styles.message}>
            {`${t('auth-have-no-account')}`}{' '}
            <Link to={'/sign-up'}>
              <span className={styles.link}>{`${t('auth-register')}`}</span>
            </Link>
          </p>
          <p className={styles.message}>
            {`${t('auth-forgot-password')}`}{' '}
            <Link to={'/forgot-password'}>
              <span className={styles.link}>{`${t('auth-forgot-password')}`}</span>
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
