import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/basic/button';
import Checkbox from 'components/basic/checkbox';
import Goback from 'components/ui/go-back';
import LoadingScreen from 'components/ui/loading-screen';
import PlaceHolderImage from 'assets/image/placeholder.jpg';
import styles from './style.module.scss';
import { getRetailerData, getAvailableSolutions } from 'utils/api/shop';
import { saveSolutionList } from 'store/shopping/actions';
import { shoppingListSelector } from 'store/shopping/hooks';
import { useAppDispatch, useAppSelector } from 'store';

const ShoppingListPlan = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const shopping = useAppSelector(shoppingListSelector);

  const [retailers, setRetailers] = useState<RetailerType[]>([]);
  const [isCheckRetailers, setIsCheckRetailers] = useState(false);
  const [selectedRetailerIds, setselectedRetailerIds] = useState<number[]>([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getRetailerData();
      setRetailers(response?.data?.retailers);
    };
    getData();
  }, []);

  const getSolutions = async (ids: number[]) => {
    const response = await getAvailableSolutions({ id: shopping?.selectedList?.id, ids });
    dispatch(saveSolutionList(response?.data?.versions));
  };

  return !!retailers?.length ? (
    <div className={styles.wrapper}>
      <Goback title={`${t('shopping-lets-plan-the-optimal-shopping')}`} />
      <div className={styles.alert}>
        <p>{t('shopping-list-warn-message')}</p>
        <div className={styles.buttonWrapper}>
          <Button
            label={`${t('shopping-no')}`}
            onClick={() => {
              setIsCheckRetailers(true);
            }}
          />
          <Button
            label={`${t('shopping-yes')}`}
            onClick={() => {
              setIsCheckRetailers(false);
              getSolutions(retailers.map(item => item.id));
              navigator('/shopping/solutions');
            }}
          />
        </div>
      </div>
      {isCheckRetailers && (
        <>
          <div className={styles.stores}>
            <div className={styles.titleWrapper}>
              <p className={styles.title}>{t('shopping-select-stores')}</p>
              <Checkbox
                name="all"
                value={selectedRetailerIds?.length === retailers?.length}
                onChange={(name, value) => {
                  if (value) {
                    setselectedRetailerIds((retailers ?? []).map(item => item?.id));
                  } else {
                    setselectedRetailerIds([]);
                  }
                }}
              >
                {t('shopping-all')}
              </Checkbox>
            </div>
            {(retailers ?? []).map((retailer: RetailerType) => (
              <div key={retailer?.id}>
                <Checkbox
                  name={retailer.name ?? ''}
                  value={!!selectedRetailerIds.find(item => item === retailer.id)}
                  onChange={(name, value) => {
                    if (value) {
                      setselectedRetailerIds(prev => [...prev, retailer.id ?? 0]);
                    } else {
                      setselectedRetailerIds(prev => prev.filter(item => item !== retailer.id));
                    }
                  }}
                >
                  <div className={styles.cardWrapper}>
                    <img
                      src={
                        retailer?.photo
                          ? `${process.env.REACT_APP_BACKEND_BASEURL}${retailer?.photo}`
                          : PlaceHolderImage
                      }
                      className={styles.img}
                    />
                    <p className={styles.name}>{retailer?.name}</p>
                  </div>
                </Checkbox>
              </div>
            ))}
          </div>
          <Button
            label={`${t('shopping-next')}`}
            onClick={() => {
              getSolutions(selectedRetailerIds);
              navigator('/shopping/solutions');
            }}
            disable={!selectedRetailerIds?.length}
          />
        </>
      )}
    </div>
  ) : (
    <div>
      <LoadingScreen />
    </div>
  );
};

export default ShoppingListPlan;
