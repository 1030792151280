import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import classNames from 'classnames';

import Logo from 'assets/image/logo.png';
import Button from 'components/basic/button';
import { userSelector } from 'store/user/hooks';
import { useAppSelector } from 'store';
import styles from './styles.module.scss';
import { Switch } from '@headlessui/react';

const Welcome = () => {
  const auth = useAppSelector(userSelector);
  const lang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  const [enabled, setEnabled] = useState(lang === 'hu');

  useEffect(() => {
    if (enabled) {
      i18n.changeLanguage('hu');
      localStorage.setItem('lang', 'hu');
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
    }
  }, [enabled]);

  return auth?.token ? (
    <Navigate to="/" replace />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.toggleWrapper}>
        <span className="text-12 text-secondary font-bold">En/Hu</span>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(styles.toggle, { [styles.active]: enabled })}
        >
          <span className={classNames(styles.toggleCircle, { [styles.active]: enabled })} />
        </Switch>
      </div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={Logo} className={styles.logoImage} />
          <p className={styles.welcome}>{`${t('welcome-to')}`}</p>
          <p className={styles.appName}>{`${t('bakery-app')}`}</p>
        </div>
        <div className={styles.buttonGroup}>
          <div>
            <Link to="/sign-in">
              <Button label={`${t('auth-login')}`} />
            </Link>
          </div>
          <div>
            <Link to="/sign-up">
              <Button label={`${t('auth-register')}`} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
