/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';

const baseUrl = process.env.REACT_APP_API_BASEURL ?? '';

const customizedAxios = (isPrivate = false) => {
  axios.create();
  axios.defaults.baseURL = baseUrl;

  if (!isPrivate) return axios;

  axios.interceptors.request.use(
    async (config: any) => {
      const accessToken = localStorage.getItem('token');

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return config;
    },
    error => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: any) => {
      if (error?.response?.data?.message === 'Not authorized') {
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        window.location.href = '/welcome';
      } else {
        console.log(error.message);
      }
      return Promise.reject(error);
    }
  );

  return axios;
};

export default customizedAxios;
