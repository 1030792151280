import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HomeIcon, NewspaperIcon, ShoppingCartIcon, HeartIcon, ReceiptPercentIcon } from '@heroicons/react/24/solid';

import styles from './style.module.scss';

const Navigator = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      {[
        { label: t('nav-home'), icon: HomeIcon, link: '/' },
        { label: t('nav-news'), icon: NewspaperIcon, link: '/news' },
        { label: t('nav-shopping'), icon: ShoppingCartIcon, link: '/shopping/management' },
        { label: t('nav-favourite'), icon: HeartIcon, link: '/favour' },
        { label: t('nav-cashback'), icon: ReceiptPercentIcon, link: '/cashback' },
      ].map(item => (
        <div className={styles.navItem} key={item.label}>
          <Link to={item.link}>
            <item.icon width={24} height={24} className={styles.icon} />
            <span>{item.label}</span>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Navigator;
