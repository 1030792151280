import React, { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import Goback from 'components/ui/go-back';
import BlogItem from 'components/ui/blogItem';
import styles from './style.module.scss';
import { getBlogData } from 'utils/api/blog';

const Blogs = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState<BlogType[]>([]);

  useEffect(() => {
    const getBlogs = async () => {
      setIsLoading(true);
      const res = await getBlogData(undefined);
      setBlogs(res?.data?.blogs);
      setIsLoading(false);
    };
    getBlogs();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Goback title={`${t('nav-news')}`} />
      {isLoading ? (
        <div className={styles.loading}>
          <Bars color="#171100" width={30} height={30} />
        </div>
      ) : (
        <div className={styles.blogs}>
          {blogs?.map(blog => (
            <BlogItem key={blog?.id} {...blog} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Blogs;
