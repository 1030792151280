import React, { memo } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner';

import Modal from 'components/basic/modal';
import PlusMinusInput from 'components/basic/plus-minus-input';
import styles from './style.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { shoppingListSelector } from 'store/shopping/hooks';
import { updateQuantityOfProducts, createShoppingList } from 'store/shopping/actions';
import { PlusCircleIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

type CreateShoppingListModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  product?: ProductType;
};

const CreateShoppingListModal: React.FC<CreateShoppingListModalProps> = ({ showModal, setShowModal, product }) => {
  const { t } = useTranslation();
  const shopping = useAppSelector(shoppingListSelector);
  const dispatch = useAppDispatch();

  const onCreateNew = () => {
    dispatch(createShoppingList(`Bevásárló lista #${shopping.list.length + 1}`));
  };

  const updateQuantity = (list_id: number, quantity: number) => {
    dispatch(
      updateQuantityOfProducts({
        list_id: list_id ?? 0,
        product_id: product?.id ?? 0,
        quantity: quantity,
      })
    );
  };

  return (
    <Modal isOpen={showModal} setIsOpen={setShowModal}>
      {shopping.loading !== 'succeeded' ? (
        <div className={styles.loading}>
          <RotatingLines strokeColor="white" strokeWidth="3" animationDuration="0.75" width="35" visible={true} />
        </div>
      ) : null}
      <div className={styles.addShopping}>
        <p className={styles.title}>{product?.name}</p>
        <p className={styles.price}>{`${t('product-price')}: ${product?.price ?? 0} Ft`}</p>
        <p className={styles.package}>{`${t('product-package')}: ${product?.package} ${product?.unit}`}</p>
        {shopping?.list?.length > 0 &&
          shopping?.list?.map(item => {
            const quantity = (item.products ?? []).find((pro: ProductType) => pro.id === product?.id)?.quantity ?? 0;
            return (
              <div className={styles.shoppingList} key={item?.id}>
                <div className={styles.shoppingListItem}>
                  <div className={styles.shoppingCart}>
                    <ShoppingCartIcon width={24} height={24} className={styles.shoppingCartIcon} />
                    <PlusSmallIcon width={12} height={12} className={styles.plusIcon} strokeWidth={5} />
                  </div>
                  <p>{item?.name}</p>
                </div>
                <div className={styles.gty}>
                  <PlusMinusInput
                    value={quantity}
                    onPlus={() => updateQuantity(item.id, quantity + 1)}
                    onMinus={() => updateQuantity(item.id, quantity - 1)}
                  />
                </div>
              </div>
            );
          })}
        <div className={styles.shoppingPlan}>
          <div className={styles.shoppingListItem}>
            <div className={styles.shoppingCart}>
              <ShoppingCartIcon width={24} height={24} className={styles.shoppingCartIcon} />
              <PlusSmallIcon width={12} height={12} className={styles.plusIcon} strokeWidth={5} />
            </div>
            <p>{`${t('product-create-new-shopping-list')}`}</p>
          </div>
          <div className={styles.gty}>
            <PlusCircleIcon width={24} height={24} color="red" className={styles.icon} onClick={onCreateNew} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(CreateShoppingListModal);
