import React, { ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

import styles from './style.module.scss';

type DropdownMenuProps = {
  options: {
    label: string;
    icon: ReactNode;
    onClick: VoidFunction;
  }[];
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ options = [] }) => {
  return (
    <div className={styles.wrapper}>
      <Menu as="div" className={styles.menu}>
        <Menu.Button>
          <EllipsisVerticalIcon className={styles.buttonIcon} aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {options.length > 0 ? (
            <Menu.Items className={styles.items}>
              <div className={styles.itemWrapper}>
                {options.map(option => (
                  <Menu.Item key={option.label}>
                    <button className={styles.item} onClick={option.onClick}>
                      {option.icon}
                      {option.label}
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          ) : (
            <div></div>
          )}
        </Transition>
      </Menu>
    </div>
  );
};

export default DropdownMenu;
