import classNames from 'classnames';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import styles from './styles.module.scss';

type TextInputProps = {
  type?: React.HTMLInputTypeAttribute;
  name?: string;
  label?: string;
  placeholder?: string;
  val?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  register?: UseFormRegisterReturn;
};

const TextInput: React.FC<TextInputProps> = ({
  name,
  type = 'text',
  label = '',
  placeholder = '',
  register,
  val,
  onChange,
}) => (
  <div className={styles.wrapper}>
    <label htmlFor={name} className={styles.label}>
      {label}
    </label>
    <input
      type={type}
      id={name}
      className={classNames(styles.input)}
      value={val}
      placeholder={placeholder}
      onChange={onChange}
      {...register}
    />
  </div>
);

export default TextInput;
