import axios from '../axios';

const getHomepageData = async (category?: 'best' | 'category' | 'lactose' | 'popular' | 'vegan') => {
  try {
    const resource = category ? `/home/${category}` : '/home';
    const { data } = await axios(true).get(resource);
    return data;
  } catch (error) {
    return null;
  }
};

const getPopularOfRetailer = async (id: number) => {
  try {
    const { data } = await axios(true).get(`/retailers/${id}/products`);
    return data;
  } catch (error) {
    return null;
  }
};

const getPopularOfCategory = async (id: number) => {
  try {
    const { data } = await axios(true).get(`/categories/${id}/products`);
    return data;
  } catch (error) {
    return null;
  }
};

export { getHomepageData, getPopularOfRetailer, getPopularOfCategory };
