import React from 'react';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

const PageNotFound = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.message}>
        <p>Page Not Found</p>
        <Link to="/">Go to home</Link>
      </div>
    </div>
  );
};

export default PageNotFound;
