import axios from '../axios';

const getShopData = async (id?: string) => {
  try {
    const resource = id ? `/shops/${id}` : '/shops';
    const { data } = await axios(true).get(resource);
    return data;
  } catch (error) {
    return [];
  }
};

const getRetailerData = async (id?: string) => {
  try {
    const resource = id ? `/retailers/${id}` : '/retailers';
    const { data } = await axios(true).get(resource);
    return data;
  } catch (error) {
    return [];
  }
};

const getAvailableSolutions = async ({ id, ids }: { id?: number; ids?: Array<number> }) => {
  try {
    const resource = `/lists/${id}`;
    const { data } = await axios(true).put(resource, { retailers: ids });
    return data;
  } catch (error) {
    return [];
  }
};

const getPlanDetail = async (id: number) => {
  try {
    const resource = `/lists/plan/${id}`;
    const { data } = await axios(true).get(resource);
    return data;
  } catch (error) {
    return [];
  }
};

const getShopsOfRetailer = async (id: string) => {
  try {
    const resource = `/retailers/${id}/shops`;
    const { data } = await axios(true).get(resource);
    return data;
  } catch (error) {
    return [];
  }
};

const saveZone = async (params: {
  latitude: number;
  longitude: number;
  diameter: number;
  retailers: (number | undefined)[];
}) => {
  try {
    const { data } = await axios(true).put('/zone', params);
    return data;
  } catch (error) {
    return [];
  }
};

const getZone = async () => {
  try {
    const { data } = await axios(true).get('/zone');
    return data;
  } catch (error) {
    return [];
  }
};

export { getShopData, getRetailerData, getShopsOfRetailer, saveZone, getZone, getAvailableSolutions, getPlanDetail };
