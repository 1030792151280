import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import BlogDetail from 'views/blog-detail';
import Blogs from 'views/blogs';
import CoommingSoon from 'views/comming-soon';
import Favourite from 'views/favour';
import ForgotPassword from 'views/forgot-password';
import Home from 'views/home';
import LoadingScreen from 'components/ui/loading-screen';
import PageNotFound from 'views/404';
import PrivateLayout from 'layout/private';
import ProductDetailMore from 'views/product-detail';
import ProductList from 'views/producet-list';
import ResetPassword from 'views/reset-password';
import SelectShoppingZone from 'views/select-shopping-zone';
import ShoppingListSolutionDetail from 'views/shopping-list-solution-detail';
import ShoppingListManager from 'views/shopping-list-management';
import ShoppingListPlan from 'views/shopping-list-plan';
import ShoppingListProductDetail from 'views/shopping-list-product-detail';
import ShoppingListSolutions from 'views/shopping-list-solutions';
import SignIn from 'views/sign-in';
import SignUp from 'views/sign-up';
import Welcome from 'views/welcome';
import axios from 'utils/axios';
import { useAppDispatch } from 'store';
import { saveUserData } from 'store/user/actions';

const router = createBrowserRouter([
  {
    path: '/welcome',
    element: <Welcome />,
  },
  {
    path: '/sign-in',
    element: <SignIn />,
  },
  {
    path: '/sign-up',
    element: <SignUp />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/',
    element: <PrivateLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/products/category/:id',
        element: <ProductList />,
      },
      {
        path: '/favour',
        element: <Favourite />,
      },
      {
        path: '/news',
        element: <Blogs />,
      },
      {
        path: '/news/:id',
        element: <BlogDetail />,
      },
      {
        path: '/product/:id',
        element: <ProductDetailMore />,
      },
      {
        path: '/shopping/plan',
        element: <ShoppingListPlan />,
      },
      {
        path: '/shopping/solutions',
        element: <ShoppingListSolutions />,
      },
      {
        path: '/shopping/solutions/:id',
        element: <ShoppingListSolutionDetail />,
      },
      {
        path: '/shopping/management',
        element: <ShoppingListManager />,
      },
      {
        path: '/shopping/management/product/:id',
        element: <ShoppingListProductDetail />,
      },
      {
        path: '/zone',
        element: <SelectShoppingZone />,
      },
      {
        path: '/cashback',
        element: <CoommingSoon />,
      },
      {
        path: '/profile',
        element: <CoommingSoon />,
      },
      {
        path: '/reset-password',
        element: <CoommingSoon />,
      },
      {
        path: '/contact',
        element: <CoommingSoon />,
      },
      {
        path: '/cookie',
        element: <CoommingSoon />,
      },
      {
        path: '/delete-account',
        element: <CoommingSoon />,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setIsLoading(true);
    const autoLogin = async () => {
      try {
        const email = localStorage.getItem('email');
        const token = localStorage.getItem('token');
        if (email && token) {
          const res = await axios(false).post('/login', { email, token });
          if (res?.data?.data?.success) {
            localStorage.setItem('email', res?.data?.data?.email ?? '');
            localStorage.setItem('token', res?.data?.data?.token ?? '');
            dispatch(saveUserData(res?.data?.data));
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    autoLogin();
  }, []);

  return isLoading ? <LoadingScreen /> : <RouterProvider router={router} />;
}

export default App;
