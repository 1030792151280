import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import Navigator from 'layout/navigator';
import SubMenu from 'components/ui/sub-menu';
import SearchPanel from 'components/ui/search';
import Logo from 'assets/image/logo.png';
import styles from './style.module.scss';
import { useAppDispatch } from 'store';
import { getShoppingLists } from 'store/shopping/actions';

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const lang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [enabled, setEnabled] = useState(lang === 'hu');
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [isSearchPanel, setIsSearchPanel] = useState(false);

  useEffect(() => {
    if (enabled) {
      i18n.changeLanguage('hu');
      localStorage.setItem('lang', 'hu');
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
    }
    dispatch(getShoppingLists());
  }, [enabled]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Link to="/" className={styles.logoSection}>
            <img src={Logo} className={styles.logo} />
            <p className={styles.name}>{t('app-name')}</p>
          </Link>
          <div className={styles.search}>
            <span className="text-12">En/Hu</span>
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(styles.toggle, { [styles.active]: enabled })}
            >
              <span className={classNames(styles.toggleCircle, { [styles.active]: enabled })} />
            </Switch>
            <button onClick={() => setIsSearchPanel(true)}>
              <MagnifyingGlassIcon width={24} height={24} />
            </button>
            <button onClick={() => setIsSubMenu(true)}>
              <Bars3Icon width={24} height={24} />
            </button>
          </div>
        </div>
        <div className={styles.children}>{children}</div>
        <div className={styles.footer}>
          <Navigator />
        </div>
      </div>
      <SubMenu setShow={setIsSubMenu} isShow={isSubMenu} />
      <SearchPanel setShow={setIsSearchPanel} isShow={isSearchPanel} />
    </div>
  );
};

export default MainLayout;
