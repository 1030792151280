import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';
import Button from '../../basic/button';

type SolutionItemProps = {
  id: number;
  retailers?: RetailerDetailType[];
  savings?: number;
  save_percent?: number;
  total?: number;
};

const SolutionCard: React.FC<SolutionItemProps> = ({ id, retailers = [], savings, save_percent, total }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const allRetailersName = retailers
    ?.map(item => item?.retailer_name)
    ?.filter(Boolean)
    ?.join(', ');
  const shopCount = retailers?.reduce((acc, item) => acc + (item?.shop?.length || 0), 0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.infoSection}>
        <p className={styles.title}>
          {`${t('shopping-the-cheapeast-solution-in')}`}
          <span className={styles.boldText}>{` ${shopCount} `}</span>
          {`${t('shopping-shops-lowercase')}`}
        </p>
        <p className={styles.boldTitle}>
          {`${t('shopping-all-products-are-available')}`}
          {' - '}
          <span className={styles.boldText}>{allRetailersName}</span>
        </p>
        <div className={styles.infoBox}>
          <p>{`${t('shopping-total-basket-value')}`}</p>
          <p className={styles.boldText}>{`${total} Ft`}</p>
        </div>
        <div className={styles.info}>
          <p>
            {`${t('you-will-save')}`}
            <span className={styles.boldText}> {savings} Ft</span>
          </p>
          <span>{' => '}</span>
          <span className={styles.boldText}>{save_percent}%</span>
        </div>
        <div className={styles.buttonWrapper}>
          <Button type="button" label={`${t('view-detail')}`} onClick={() => navigate(`/shopping/solutions/${id}`)} />
        </div>
      </div>
    </div>
  );
};

export default memo(SolutionCard);
