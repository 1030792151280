import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  UserIcon,
  LockClosedIcon,
  ShoppingBagIcon,
  ArrowLeftOnRectangleIcon,
  Cog8ToothIcon,
  TrashIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';

import Drawer from 'components/basic/drawer';
import LoadingScreen from '../loading-screen';
import axios from 'utils/axios';
import { useAppDispatch } from 'store';
import { deleteUserData } from 'store/user/actions';

import styles from './style.module.scss';

type SubMenuProps = {
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const SubMenu: React.FC<SubMenuProps> = ({ isShow, setShow }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      await axios(true).post('/logout');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    dispatch(deleteUserData());
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <div className={styles.wrapper}>
      <Drawer title={`${t('menu-menu')}`} isShow={isShow} setShow={setShow}>
        {[
          { label: t('menu-profile'), icon: UserIcon, link: '/profile' },
          { label: t('menu-password-reset'), icon: LockClosedIcon, link: '/reset-password' },
          { label: t('menu-shopping-zone'), icon: ShoppingBagIcon, link: '/zone' },
          { label: t('menu-faq-help'), icon: QuestionMarkCircleIcon, link: '/news' },
          { label: t('menu-contact-us'), icon: PhoneIcon, link: '/contact' },
          { label: t('menu-gtc-gdpf-cookie-management'), icon: Cog8ToothIcon, link: '/cookie' },
          { label: t('menu-delete-my-account'), icon: TrashIcon, link: '/delete-account' },
        ].map(item => (
          <Link to={item?.link} className={styles.subMenuItem} key={item.label} onClick={() => setShow(false)}>
            <div className={styles.labelWrapper}>
              <item.icon width={24} height={24} />
              <span className={styles.label}>{item.label}</span>
            </div>
            <ChevronRightIcon width={16} height={16} />
          </Link>
        ))}
        <div className={styles.subMenuItem} onClick={handleLogout}>
          <div className={styles.labelWrapper}>
            <ArrowLeftOnRectangleIcon width={24} height={24} />
            <span className={styles.label}>{t('menu-logout')}</span>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default SubMenu;
