import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  updateQuantityOfProducts,
  createShoppingList,
  deleteShoppingList,
  getShoppingLists,
  updateShoppingList,
  getShoppingListDetail,
  saveSelectedList,
  saveSolutionList,
  saveSelectedSolution,
  getSavedSolutionList,
  deleteSavedSolution,
} from './actions';

type ActionType = PayloadAction<
  void,
  string,
  {
    arg: void;
    requestId: string;
    requestStatus: 'fulfilled';
  },
  never
>;

const PREFIX = 'shopping';

type SelectedListType = {
  id: number;
  name: string;
  products?: ProductType[];
};

type ShoppingStateType = {
  list: ShoppingListType[];
  selectedList?: SelectedListType;
  solutionList?: SolutionType[];
  savedSolutionList?: SavedSolutionType[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
};

const initialState: ShoppingStateType = {
  list: [],
  selectedList: undefined,
  solutionList: undefined,
  savedSolutionList: undefined,
  loading: 'idle',
};

const isPendingAction = (action: ActionType) => action.type.startsWith(`${PREFIX}/`) && action.type.endsWith('pending');
const isRejectedAction = (action: ActionType) =>
  action.type.startsWith(`${PREFIX}/`) && action.type.endsWith('rejected');

export const shoppingSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getShoppingLists.fulfilled, (state, action) => {
        state.list = action.payload?.data?.items ?? [];
        state.loading = 'succeeded';
      })
      .addCase(getShoppingListDetail.fulfilled, (state, action) => {
        state.selectedList = action.payload?.data ?? [];
        state.loading = 'succeeded';
      })
      .addCase(createShoppingList.fulfilled, (state, action) => {
        state.list = action.payload?.data?.items ?? [];
        state.loading = 'succeeded';
      })
      .addCase(updateShoppingList.fulfilled, (state, action) => {
        state.list = action.payload?.data?.items ?? [];
        state.loading = 'succeeded';
      })
      .addCase(deleteShoppingList.fulfilled, (state, action) => {
        state.list = action.payload?.data?.items ?? [];
        state.loading = 'succeeded';
      })
      .addCase(updateQuantityOfProducts.fulfilled, (state, action) => {
        state.list = action.payload?.data?.items ?? [];
        state.loading = 'succeeded';
      })
      .addCase(saveSelectedSolution.fulfilled, (state, action) => {
        state.savedSolutionList = action.payload?.data?.versions;
        state.loading = 'succeeded';
      })
      .addCase(getSavedSolutionList.fulfilled, (state, action) => {
        state.savedSolutionList = action.payload?.data?.versions;
        state.loading = 'succeeded';
      })
      .addCase(deleteSavedSolution.fulfilled, (state, action) => {
        state.savedSolutionList = action.payload?.data?.versions;
        state.loading = 'succeeded';
      })
      .addCase(saveSelectedList, (state, action) => {
        state.selectedList = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(saveSolutionList, (state, action) => {
        state.solutionList = action.payload;
        state.loading = 'succeeded';
      })
      .addMatcher(isPendingAction, state => {
        state.loading = 'pending';
      })
      .addMatcher(isRejectedAction, state => {
        state.loading = 'failed';
      });
  },
});

export default shoppingSlice.reducer;
