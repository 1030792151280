import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

import styles from './style.module.scss';

const LoadingScreen = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <RotatingLines width="80" strokeColor="grey" ariaLabel="bars-loading" visible={true} />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
