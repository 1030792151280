import React from 'react';
import { CheckCircleIcon, ArrowTrendingDownIcon, ArrowTrendingUpIcon, MinusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import PlaceHolderImage from 'assets/image/placeholder.jpg';
import styles from './style.module.scss';
import CustomDisclosure from 'components/basic/disclosure';
import PlusMinusInput from 'components/basic/plus-minus-input';
import { updateQuantityOfProducts } from 'store/shopping/actions';
import { useAppDispatch } from 'store';

type StoreItemProps = {
  list_id?: number;
  product?: ProductType;
  type?: 'PRODUCT' | 'SHOP';
  isSimple?: boolean;
  controlAmount?: boolean;
};

const ListItem: React.FC<StoreItemProps> = ({
  list_id,
  product,
  isSimple = false,
  type = 'SHOP',
  controlAmount = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const saving = 3;

  const updateQuantity = (quantity: number) => {
    dispatch(
      updateQuantityOfProducts({
        list_id: list_id ?? 0,
        product_id: product?.id ?? 0,
        quantity: quantity,
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.infoSection}>
        <img
          src={product?.photo ? `${process.env.REACT_APP_BACKEND_BASEURL}${product?.photo}` : PlaceHolderImage}
          className={styles.img}
        />
        <div className={styles.textArea}>
          <p className={styles.storeName}>{product?.name}</p>
          {type === 'PRODUCT' && product?.price && <p className={styles.info}>{product?.retailer_name}</p>}
          {product?.price && (
            <p className={styles.info}>
              {`${t('product-price')}: ${product?.price} Ft `}
              <ArrowTrendingUpIcon width={18} height={18} className={styles.chartGood}></ArrowTrendingUpIcon>
            </p>
          )}
          {type === 'PRODUCT' && (
            <>
              {product?.saving && (
                <div className={styles.savingWrapper}>
                  <p className={styles.info}>{`$${saving} ${t('shopping-savings')}`}</p>
                  <div className={styles.trending}>
                    {(saving ?? 0) > 2 && <ArrowTrendingUpIcon width={18} height={18} className={styles.chartGood} />}
                    {(saving ?? 0) < -2 && (
                      <ArrowTrendingDownIcon width={18} height={18} className={styles.chartDanger} />
                    )}
                    {(saving ?? 0) >= -2 && (saving ?? 0) <= 2 && (
                      <MinusIcon width={18} height={18} className={styles.chargNormal} />
                    )}
                    <p>{saving ?? 0}%</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {type === 'PRODUCT' && (
          <>
            {controlAmount ? (
              <div className={styles.productNo}>
                {(() => {
                  const quantity = product?.quantity ?? 0;
                  return (
                    <PlusMinusInput
                      value={quantity}
                      onMinus={() => updateQuantity(quantity - 1)}
                      onPlus={() => updateQuantity(quantity + 1)}
                    />
                  );
                })()}
              </div>
            ) : (
              <div className={styles.productNo}>
                <p className={styles.no}>No 5</p>
                <CheckCircleIcon width={20} height={20} className={styles.check} />
              </div>
            )}
          </>
        )}
      </div>
      {type === 'SHOP' && !isSimple && (
        <div className={styles.shop}>
          <CustomDisclosure buttonElement={<p className={styles.disclosureButton}>{t('product-show-address')}</p>}>
            <p className={styles.address}>Product Address here</p>
          </CustomDisclosure>
        </div>
      )}
    </div>
  );
};

export default ListItem;
