import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import styles from './style.module.scss';

type GobackProps = {
  title?: string;
};

const Goback: React.FC<GobackProps> = ({ title = '' }) => {
  const navigator = useNavigate();
  return (
    <div className={styles.wrapper} onClick={() => navigator(-1)}>
      <button>
        <ChevronLeftIcon width={24} height={24} />
      </button>
      <p className={styles.message}>{title}</p>
    </div>
  );
};

export default Goback;
