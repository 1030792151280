import React, { useState } from 'react';
import Slider from 'react-slick';
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProductCard from '../product-card';
import RetailerCard from '../retailer-card';
import classNames from 'classnames';
import styles from './style.module.scss';

type CarouselProps = {
  contentType?: 'PRODUCT' | 'RETAILER' | 'RETAILERNAME' | 'CATEGORY';
  anchor?: string;
  title?: string;
  slidesToShow?: number;
  items: ProductType[] | RetailerType[] | RetailerDetailType[] | CategoryType[];
  isLoading?: boolean;
  onActiveTab?: Function;
};

type ArrowProps = {
  onClick?: () => void;
};

const NextArrow = ({ onClick }: ArrowProps) => {
  return (
    <div onClick={onClick}>
      <ArrowRightCircleIcon width={28} height={28} className={classNames(styles.arrowIcon, { 'right-5': true })} />
    </div>
  );
};

const PrevArrow = ({ onClick }: ArrowProps) => {
  return (
    <div onClick={onClick}>
      <ArrowLeftCircleIcon width={28} height={28} className={classNames(styles.arrowIcon, { 'left-5': true })} />
    </div>
  );
};

const Carousel: React.FC<CarouselProps> = ({
  contentType = 'PRODUCT',
  anchor,
  title,
  items,
  slidesToShow = 3,
  isLoading = false,
  onActiveTab = () => {},
}) => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const [currentOrder, setCurrentOrder] = useState<number | undefined>(0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <p className={styles.title}>{title}</p>
        {anchor ? (
          <Link to={`${anchor}`} className={styles.more}>
            {t('home-more')}
          </Link>
        ) : null}
      </div>
      <div className={styles.slider}>
        {isLoading ? (
          <div className={styles.loading}>
            <Bars color="#171100" width={30} height={30} />
          </div>
        ) : (
          <>
            {items?.length > 0 ? (
              <Slider {...settings}>
                {items.map((product, index) => (
                  <div key={index}>
                    {contentType === 'PRODUCT' && <ProductCard {...(product as ProductType)} />}
                    {contentType === 'RETAILER' && <RetailerCard {...(product as RetailerType)} type="RETAILER" />}
                    {contentType === 'RETAILERNAME' && (
                      <RetailerCard
                        {...(product as RetailerDetailType)}
                        type="RETAILERNAME"
                        onClickEvent={(retailer_id: string, order: number) => {
                          onActiveTab(retailer_id);
                          setCurrentOrder(order);
                        }}
                        order={index}
                        isActivated={index === currentOrder}
                      />
                    )}
                    {contentType === 'CATEGORY' && <RetailerCard {...(product as CategoryType)} type="CATEGORY" />}
                  </div>
                ))}
              </Slider>
            ) : (
              <p className={styles.noMessage}>No Items</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Carousel;
