import { Bars } from 'react-loader-spinner';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/basic/button';
import Carousel from 'components/ui/carousel';
import CustomDisclosure from 'components/basic/disclosure';
import Goback from 'components/ui/go-back';
import PlaceHolderImage from 'assets/image/placeholder.jpg';
import styles from './style.module.scss';
import { getPlanDetail } from 'utils/api/shop';
import { saveSelectedSolution } from 'store/shopping/actions';
import { shoppingListSelector } from 'store/shopping/hooks';
import { useAppDispatch, useAppSelector } from 'store';

const ShoppingListSolutionDetail = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const shopping = useAppSelector(shoppingListSelector);

  const [retailers, setRetailers] = useState<RetailerDetailType[]>();
  const [activatedTabId, setActivatedTabId] = useState<number>();
  const [isSavedPlan, setIsSavedPlan] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      if (id) {
        const response = await getPlanDetail(parseInt(id));
        setRetailers(response?.data?.version?.retailers);
        setActivatedTabId(response?.data?.version?.retailers[0]?.id);
        setIsSavedPlan(response?.data?.version?.saved === 1 ? true : false);
      }
    };
    getData();
  }, []);

  const products = retailers?.find(retailer => retailer.id === activatedTabId)?.products || [];
  const shop = retailers?.find(retailer => retailer.id === activatedTabId)?.shop || [];

  const onSaveSelectedSolution = () => {
    if (id) {
      dispatch(
        saveSelectedSolution({
          id: parseInt(id),
        })
      );
    }
    if (shopping.loading === 'succeeded') {
      navigator('/shopping/management');
    }
  };

  return (
    <div className={styles.wrapper}>
      {retailers ? (
        <>
          <div className={styles.close}>
            <button className={styles.button} onClick={() => navigator(-1)}>
              <XCircleIcon width={30} height={30} aria-hidden="true" />
            </button>
          </div>
          <p className={styles.title}>{`${t('your-shopping-plan')}`}</p>
          <Carousel
            items={retailers ?? []}
            contentType="RETAILERNAME"
            onActiveTab={(retailer_id: number) => {
              setActivatedTabId(retailer_id);
            }}
          />
          <div className={styles.productWrapper}>
            {products.length > 0 ? (
              products.map((item, index) => (
                <div key={index} className={styles.wrapper}>
                  <div className={styles.infoSection}>
                    <img
                      src={item?.photo ? `${process.env.REACT_APP_BACKEND_BASEURL}${item?.photo}` : PlaceHolderImage}
                      className={styles.img}
                    />
                    <div className={styles.infoWrapper}>
                      <p className={styles.name}>{item.name}</p>
                      <div className={styles.info}>
                        <span className={styles.text}>{item.price} Ft</span>
                        <span className={styles.text}>{item.package}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noProductMessage}>{t('search-no-product')}</div>
            )}
          </div>
          <div className={styles.shopWrapper}>
            <CustomDisclosure
              buttonElement={
                <p className={styles.disclosureButton}>
                  {retailers?.find(retailer => retailer.id === activatedTabId)?.name} {t('shops-in-your-zone')}
                </p>
              }
            >
              {shop.map((item, index) => (
                <div key={index} className={styles.info}>
                  {item.address}
                </div>
              ))}
            </CustomDisclosure>
          </div>
          {!isSavedPlan ? (
            <div className={styles.buttonWrapper}>
              <Goback />
              <Button
                label={`${t('save')}`}
                onClick={() => onSaveSelectedSolution()}
                isLoading={shopping.loading !== 'succeeded'}
              />
            </div>
          ) : null}
        </>
      ) : (
        <div className={styles.loading}>
          <Bars color="#171100" width={30} height={30} />
        </div>
      )}
    </div>
  );
};

export default ShoppingListSolutionDetail;
