import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PlaceholderImage from 'assets/image/placeholder.jpg';
import styles from './style.module.scss';

type RetailerCardProps = {
  id?: number;
  name?: string;
  slug?: string;
  products?: ProductType[];
  photo?: string;
  type: 'CATEGORY' | 'RETAILER' | 'RETAILERNAME';
  onClickEvent?: Function;
  order?: number;
  isActivated?: boolean;
};

const RetailerCard: React.FC<RetailerCardProps> = ({
  id,
  name,
  slug,
  products,
  photo,
  type,
  onClickEvent = () => {},
  order,
  isActivated,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClick = () => {
    onClickEvent(id, order);
  };

  return (
    <>
      {type === 'RETAILERNAME' ? (
        <div className={classNames(styles.default, { [styles.active]: isActivated })} onClick={() => handleClick()}>
          {name} ({products?.length})
        </div>
      ) : (
        <div
          className={styles.wrapper}
          onClick={() => navigate(`/products/${type === 'RETAILER' ? 'popular' : 'category'}/${id}`)}
        >
          <img
            src={photo ? `${process.env.REACT_APP_BACKEND_BASEURL}/${photo}` : PlaceholderImage}
            className={styles.logo}
          />
          <p>{type === 'CATEGORY' ? `${t(slug ?? '')}` : name}</p>
        </div>
      )}
    </>
  );
};

export default RetailerCard;
