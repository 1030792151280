import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import MainLayout from 'layout/main';
import { userSelector } from 'store/user/hooks';
import { useAppSelector } from 'store';

const PrivateLayout = () => {
  const auth = useAppSelector(userSelector);
  return !auth?.token ? (
    <Navigate to="/welcome" replace />
  ) : (
    <div>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </div>
  );
};

export default PrivateLayout;
