import axios from '../axios';

const getCategoryData = async (id?: number) => {
  try {
    const resource = id ? `/categories/${id}` : '/categories';
    const { data } = await axios(true).get(resource);
    return data;
  } catch (error) {
    return [];
  }
};

const getCategoryProducts = async (id?: number) => {
  try {
    const { data } = await axios(true).get(`/categories/${id}/products`);
    return data;
  } catch (error) {
    return [];
  }
};

export { getCategoryData, getCategoryProducts };
