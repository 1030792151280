import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';

import styles from './style.module.scss';

export type OptionType = {
  name: string;
  slug: string;
  id?: number;
};

type DropdownProps = {
  label?: string;
  val?: OptionType;
  onChange: React.Dispatch<React.SetStateAction<OptionType>>;
  list: OptionType[];
};

const Dropdown: React.FC<DropdownProps> = ({ list, label = '', val, onChange }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang');

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      <Listbox value={val} onChange={onChange}>
        <div className={styles.content}>
          <Listbox.Button className={styles.button}>
            <span className="block truncate">{lang === 'hu' ? val?.name : val?.slug}</span>
            <span className={styles.icon}>
              <ChevronUpDownIcon className="text-gray" width={24} height={24} />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className={styles.options}>
              {list.map((listItem, listItemIndex) => (
                <Listbox.Option
                  key={listItemIndex}
                  className={({ active }) => `${styles.option} ${active ? styles.active : styles.diable}`}
                  value={listItem}
                >
                  <span className={`block truncate ${listItem.name === val?.name ? 'font-medium' : 'font-normal'}`}>
                    {`${t(listItem.slug ?? '')}`}
                  </span>
                  {listItem.name === val?.name ? (
                    <span className={styles.checkIconWrapper}>
                      <CheckIcon width={24} height={24} />
                    </span>
                  ) : null}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Dropdown;
