import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Carousel from 'components/ui/carousel';
import { getHomepageData } from 'utils/api/home';

import styles from './style.module.scss';

type HomeContentsDataType = {
  best: ProductType[];
  category: CategoryType[];
  lactose: ProductType[];
  popular: RetailerType[];
  vegan: ProductType[];
};

const Home = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<HomeContentsDataType>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getAllProducts = async () => {
      setIsLoading(true);
      const home = await getHomepageData();
      setData(home?.data);
      setIsLoading(false);
    };
    getAllProducts();
  }, []);

  return (
    <div className={styles.wrapper}>
      {[
        {
          anchor: '/products/best',
          products: data?.best ?? [],
          title: t('home-best-offers-of-this-week'),
          isLoading: isLoading,
          slidesToShow: 3,
          content: 'PRODUCT',
        },
        {
          anchor: '',
          products: data?.popular ?? [],
          title: t('home-popular-offers-from-our-retailers'),
          isLoading: isLoading,
          slidesToShow: 4,
          content: 'RETAILER',
        },
        {
          anchor: '/products/vegan',
          products: data?.vegan ?? [],
          title: t('home-vegetarian-and-vegan-offers'),
          isLoading: isLoading,
          slidesToShow: 3,
          content: 'PRODUCT',
        },
        {
          anchor: '/products/lactose',
          products: data?.lactose ?? [],
          title: t('home-lactose-free-products'),
          isLoading: isLoading,
          slidesToShow: 3,
          content: 'PRODUCT',
        },
        {
          anchor: '',
          products: data?.category.filter(item => item.parent_id === null) ?? [],
          title: t('home-shop-by-product-category'),
          isLoading: isLoading,
          slidesToShow: 3,
          content: 'CATEGORY',
        },
      ].map((item, index) => (
        <Carousel
          contentType={item.content as 'PRODUCT' | 'RETAILER' | 'CATEGORY'}
          anchor={item.anchor}
          items={item.products}
          title={`${item.title}`}
          slidesToShow={item.slidesToShow}
          isLoading={item.isLoading}
          key={index}
        />
      ))}
    </div>
  );
};

export default Home;
