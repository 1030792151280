import axios from '../axios';

const getProducts = async () => {
  try {
    const { data } = await axios(true).get('/products');
    return data;
  } catch (error) {
    return [];
  }
};

const getProductById = async (id: string) => {
  try {
    const { data } = await axios(true).get(`/products/${id}`);
    return data;
  } catch (error) {
    return {};
  }
};

const searchProducts = async (params: {
  label?: string;
  query?: string;
  category?: string;
  discounted?: boolean;
  retailer_id?: number;
}) => {
  try {
    const { data } = await axios(true).post(`/products`, params);
    return data;
  } catch (error) {
    return null;
  }
};

const createFavorite = async (id: number) => {
  try {
    const { data } = await axios(true).put('/favorites', { product_id: id });
    return data;
  } catch (error) {
    return null;
  }
};

const deleteFavorite = async (id: number) => {
  try {
    const { data } = await axios(true).delete(`/favorites/${id}`);
    return data;
  } catch (error) {
    return null;
  }
};

const getFavorites = async () => {
  try {
    const { data } = await axios(true).get(`/favorites`);
    return data;
  } catch (error) {
    return null;
  }
};

export { getProducts, getProductById, searchProducts, createFavorite, deleteFavorite, getFavorites };
